"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var constants_1 = require("ethers/constants");

exports.PARTICIPANT_A = '0xeF3c144c6F1B974AcDD5f7AF18057DEc22B2DCe5';
exports.PARTICIPANT_B = '0x56C6ded44Acd7c8643e1501b47285Ad5d722225B';
exports.PARTICIPANT_C = '0xe1501b47285Ad5d722225B56C6ded44Acd7c8643';
exports.ETH_TOKEN_ADDRESS = constants_1.AddressZero;
exports.APP_DEFINITION = '0xc1b63bca14C877eBC24dBcdE54659FFC9E46Ac59';
exports.APP_DATA = constants_1.Zero.toString();
exports.UPDATED_APP_DATA = constants_1.One.toString();