"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ethers_1 = require("ethers");

function calculateChannelId(participants, appDefinition) {
  var message = '';
  participants.forEach(function (p) {
    return message += p.participantId;
  });
  message += appDefinition;
  return ethers_1.utils.hashMessage(message);
}

exports.calculateChannelId = calculateChannelId;